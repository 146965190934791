@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap);
.App {
  text-align: center;
}
html{
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
}
.card p{
  text-align: justify;
  font-size: 20px;
}
body {
  color: var(--main-text-color);
  position: relative;
  background: var(--main-background-color);
  margin: 0;
  font-family: 'Comfortaa', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.content-wrapper .card{
  background-color: #d9c4af;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#link1{
  margin-top: 50px;
}
nav {
  background: var(--nav-background-color);
  width: 100%;
  height: var(--nav-height);
  transition: height 0.2s ease-in-out;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 80%;
  margin: auto;
}
.headerclub .nav{
  padding: 0px;
}
.nav-logo {
  width: var(--nav-logo-width);
}

nav a {
  font-size: var(--nav-logo-font-size);
  color: var(--nav-link-color);
  text-decoration: none;

  padding: 0px;
}
.nav-title h3{
  color: white;
  font-size: 30px;
  font-weight: lighter;
  margin-bottom: 20px;
}
nav a:hover {
  color: var(--nav-link-hover-color);
  background-color: var(--nav-link-hover-background-color);
}

.mobile-menu-button {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 37px;
  right: -50px;
  transition: right 0.2s ease-in-out;
  z-index: 45;
  cursor: pointer;
}

.open-menu {
  left: 0px !important;
}

.mobile-nav {
  position: fixed;
  min-height: 100vh;
  top: 0px;
  background: rgb(117, 69, 69);
  background: var(--mobile-nav-background-color, rgb(117, 69, 69));
  left: -100%;
  z-index: 50;
  width: 100%;
  transition: left 0.3s ease;
}

.mobile-nav-close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.mobile-nav-close-button img {
  height: 100%;
  width: 100%;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 40px;
  list-style: none;
}

.mobile-nav li {
  list-style: none;
  padding: 20px;
}

.mobile-nav a {
  text-decoration: none;
  color: var(--mobile-nav-link-color);
  font-size: var(--mobile-nav-link-size);
}

.mobile-nav a:hover {
  color: var(--mobile-nav-link-hover-color);
}

.mobile-nav-logo {
  height: var(--nav-logo-height);
}

.text-muted {
  color: darkgrey;
}

.nav-social {
  width: 30px;

  padding: 0 10px 0 10px;
}

.social-icons {
  display: flex;
  justify-content: flex-end;
  margin-right: -15px;
}

@media only screen and (max-width: 800px) {
  .hide-800 {
    display: none;
  }

  .mobile-menu-button {
    right: 37px;
  }


  nav {
    height: 100px;
  }
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

header {
  max-width: 900px;
  margin: auto;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 40px;
  display: grid;
  grid-gap: 40px;
  gap: 40px;
  padding: 35px;
  flex-direction: column;
}
@font-face {
  font-family: proxima;
  src: url("/static/media/Proxima Nova Font.7bc9ce40.otf");
}
.headerclub{

  margin: auto;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 40px;
  display: grid;
  grid-gap: 40px;
  gap: 40px;

  flex-direction: column;

}
.headerclub > img{
  height: 800px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}
.__2 > img{
  height: 100%!important;
}
@media(min-width: 1650px)
{
  .mainbanner{
    height: 950px!important;
  }
}
@media(max-width: 600px){
  .mainbanner{
    background: url(/static/media/bannergif.11489e10.gif) no-repeat!important;
    background-size: contain!important;
    height: 300px!important;
    width: 100%!important;
  }
}
.mainbanner{
  background: url(/static/media/banner-normal.2cbe4e83.jpg) no-repeat;
  background-size: cover;
   height: 800px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}
.mainbanner:hover{
  background: url(/static/media/banner-hover.cbb09b45.jpg) no-repeat;
  background-size: cover;
   height: 800px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  cursor: pointer;
}
.headermint>img{
  width: 100%;
}
@media (max-width: 600px){
  .headerclub > img{
    width: 100%;
    height: 300px;
  }
  .card-club-area button{
    font-size: 18px!important;
  }
  .card-club-area{
    bottom: 54%!important;
    left: 0%!important;
  }
  footer h3{
    margin-bottom: 20px;
  }
  footer h2{
    font-size: 20px!important;
  }
  .subscribe-container__title{
    font-size: 1.2em!important;
  }
}
.headerclub .card{

  height: 75vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.mintHeader {
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}
.hide800{
  text-align: left;
    }
@media only screen and (min-width: 800px) {
  header {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    grid-gap: 20px;
    gap: 20px;
  }
  .hide800{
    display: none;
  }
 
}

.text-primary-color {
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: var(--text-secondary-color);
}

.team {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 20px;
  margin: 40px auto;
  max-width: 900px;
}

.team-item {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px -13px white;
}

.faq {
  max-width: 900px;
  margin: auto;
  align-items: center;
  padding: 20px;
}

.faq div {
  margin-bottom: 20px;
}

.faq p {
  padding: 20px;
}

.rainbow-gallery {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.rainbow-gallery img {
  width: 100%;
}

.card {
  align-items: center;
  border-radius: var(--card-border-radius);
  padding: 40px;
  background: var(--card-background-color);
  box-shadow: var(--card-shadow);
}
.headerclub .card{
  box-shadow: none;
}
.card-club-area{
  align-items: center;
  padding: 40px;
  background: transparent;
  position: absolute;
bottom: 33%;
  left: 10%;
}
.card-club-area p{
  text-align: left;
  font-size: 25px;
  color: white;
  font-weight: 700;
}
@font-face {
  font-family: Chainprinter;
  src: url("/static/media/Chainprinter Regular.3b97bd72.ttf");
}
.club-text{
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.club-text h2{
  font-family: Chainprinter;
  color: #178964;
  text-align: left;
  font-size: 40px;
  font-weight: 100;
}
.club-text p{
  color: white;
  text-align: left;
  font-weight: 100;
  margin-top: 30px;
  font-size: 22px;
  line-height: 26px;
}
.club-text b{
  color: orangered;
}
.container {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}
.card-club-area button{
  margin-top: 20px;
      padding: 10px;
      border-radius: 25px;
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: none;
      border: 0px;
      background-color: darkgreen;
      font-size: 25px;
      font-weight: lighter;
      font-family: 'Comfortaa', cursive;

      align-self: center;
}
button:hover{
  cursor: pointer;
  background-color: rgba(219, 226, 219, 0.849);

}
#story-but button{
  margin-top: 20px;
      padding: 10px;
      border-radius: 5px;
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: none;
      border: 0px;
      background-color: darkgreen;
      font-size: 15px;
      font-weight: 600;
}
#story-but button:hover{
  cursor: pointer;
  background-color: rgba(0, 100, 0, 0.486);

}
.card-club-area button:first-of-type:hover{
  cursor: pointer;
  background-color: #92d1bd;
  color: black;

}
.card-club-area button:first-of-type{
  margin-right: 20px;
  background-color: #178964;
}
.card-club-area button:last-of-type{
  color: #fff;
}
.headerclub hr{
  background-color: #178964;
  height: 3px;
  border: 0px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 17px;
}
.card-club-area hr{
position: absolute;
bottom: 10px;
width: 80%;
}
.caption{
display: block;
color: white;
}
.benefits{
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 40px;
}
.caption{
  font-size: 15px;
}
.benefit-item:first-of-type .caption{
  font-size: 13px;
}
.headerclub h1{
  color: white;
}
.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1400px;
  margin: auto;
}

.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-box {
  background-color: var(--minting-box-color) !important;
  color: var(--minting-box-text-color) !important;
}

.minting-box h5 {
  color: var(--minting-box-heading-color);
}

.minting-info {
  background-color: var(--minting-box-info-box-color);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-info h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.minting-button {
  background: var(--minting-button-color) !important;
}

.custom-mint-container {
  margin-bottom: 20px;
 }



.loading:after {
  content: " .";
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}
.roadmap ul{
  margin-left: 10px;
}
.roadmap p {
  padding: 20px;
}
.roadmap div {
  margin-bottom: 20px;
}

.roadmap {
  max-width: 900px;
  margin: auto;
  align-items: center;
  padding: 20px;
  margin-top: 100px;
}
.vertical-timeline-element-icon{
  width:20px!important;
  height:20px!important;
  margin-left :-10px!important;
  margin-top: 20px;
}
.vertical-timeline-element-date{
  color: white;
  margin-right: 50px;
  margin-left: -50px;
}
#benefits{
  margin-top: 150px;
}
#benefits .container-card {
	display: flex;
	flex-wrap: wrap;
	justify-content: center
}
#benefits .container-card h5 {
	color: rgb(0, 0, 0);


	font-size: 20px;
	font-weight: 500;
	text-align: center;

	line-height: 1;
	letter-spacing: -1px;
	padding-top: 20px;
  padding-bottom: 20px;
}
#benefits .text-left{
  text-align: left;
  padding: 10px;
}
#benefits .about-title {
	font-size: 44px;
	color: #fff ;
	line-height: 1;
	margin-top: 0px;
  text-align: center;
  padding-left: 0px;
  font-weight: 700;
  margin-bottom: 20px;
}
#benefits .card {
	background: rgba(255, 255, 255, 0.856);
	width: 170px;
	height: 300px;
	margin: 10px;
	margin-bottom: 70px;
  border: 5px solid #d9c4af ;
  padding-top: 0px;
}
#benefits .card ul{
color: rgb(0, 0, 0);
max-width: 80%;
font-size: 18px;;
margin-left: 0px;
}
@media (max-width: 600px)
{
  #benefits .card{
    height: 1350px;
  }
  #benefits .columncard .card{
    width: 320px;
    height: 650px;
  }
}
.footer{
  background-color: var(--main-background-color);
 position: absolute;
 border-top: 1px solid #000 ;
 width: 100%;
 color: #fff ;
 font-size: 15px;
 padding-bottom: 15px;
 text-align: right;
}
.text-footer{
  padding-top: 10px;
  padding-right: 40px;
}
@media (max-width: 600px){

  #story{
    display: block!important;
    padding: 20px!important;
  }
  .story-left{
    max-width: 100%!important;
    padding-bottom: 40px!important;
  }
  #story > img{
    width: 300px!important;
    width: 95%!important;
  }
  .club-text h2{
    font-size: 25px;
    line-height: 25px;
  }
  .club-text p{
    font-size: 18px;
    line-height: 25px;
  }
}
#story{
  padding-top: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: transparent;
    margin: 0 auto;
    text-align: justify;
    padding: 100px 0;
    margin-top: 100px;
}
.story-left{
  text-align: justify;
  font-family: 'Underdog', cursive !important;
  width: 655px;
  font-size: 22px;
}
#story p{
  color: rgb(255, 255, 255);
}
#story h1{
color: rgb(255, 255, 255);
}
.social-media{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
#story-but{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.z9999{
  z-index: 9999;
}
footer{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
footer h3{
  color: white;
  font-family: 'Comfortaa', cursive;
  font-weight: 100;
  font-size: 25px;
  text-align: right;
  margin-top: 5px;
}
footer h2{
  color: white;
  font-family: 'Comfortaa', cursive;
  font-weight: 100;
  font-size: 25px;
}
.subscribe-container{
  background: transparent!important;
  color: white!important;
  border: none!important;
  box-shadow: none!important;
  display: block!important;
 padding-left: 0px!important;
  margin-top: -50px;
}
.subscribe-container__title{
  color: white!important;
  font-family: 'Comfortaa', cursive;
  text-align: left!important;
  display: block!important;
  margin-bottom: 1.3rem!important;
}
footer .nav-container{
  padding-left: 0px;
  padding-right: 0px;
  max-width: 100%!important;

}
.subscribe-container__submit{
  background-color: #178964!important;
  font-weight:100!important;
  font-family: 'Comfortaa', cursive;
  border-radius: 4px!important;
  margin-top: -5px;
  border: 0px;
}
.MuiTypography-h5{
  font-family: Comfortaa!important;
}
.MuiTypography-body1{
  font-family: proxima!important;
}

@media(max-width: 600px)
{
  .textbuy{
    margin-bottom: 40px;
  }
  .subscribe-container{
    padding-right: 0px!important;
  }
  .social-icons{
    justify-content: center!important;
    margin-right: 0px;
  }
  .hide800{
    text-align: center;
  }
  footer .nav-container{
    display: block;
  }
  .nav-logo{
    width: 70px!important;
  }
}
.nav-logo{
  width: 130px;
}
.textleft{
  display: block;

}
.textleft h1{
  font-family: Chainprinter;
  font-size: 40px;
  color: white;
  text-align: left;
  font-weight: 200;
}
.textleft p{
  font-family: proxima;
  font-size: 22px;
  text-align: justify;
  color: white;
  width: 90%;
  line-height: 26px;
}
#mobileNavContainer li{
  color: white;
}
.rightpart img{
  border-radius: 20px;
}
@media (max-width: 600px)
{
  #welcome{
    display: block!important;
  }
  .rightpart img{
    width: 300px!important;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .buytiger{
    display: block!important;
    padding: 10px!important;
  }
  .stylespecs{
    display: block!important;
  }
  .textleft h1{
    font-size: 23px;
    text-align: center;
  }
  .textleft p{
    margin-right: auto;
    margin-left: auto;
    font-size: 15px;
    font-weight: 100;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .underimg{
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    font-family: proxima;
    line-height: 18px;
  }
  .roadmap-part{
    display: block!important;
  }
  .textroad{
    font-size: 13px;
  }
  .percentage{
    font-size: 13px;
  }
  .charity{
    display: block!important;
  }
  .about{
    display: block!important;
  }
  .about .textleft{
    max-width: 90%!important;
    margin-left: auto;
    margin-right: auto;
  }
}
#welcome{
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.rightpart img{
  width: 400px;
}
.underimg{
  color: grey;
  text-align:justify;
  margin-top: 20px;
}
.buytiger{
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  border: 7px solid white;
  border-radius: 30px;
  color: white;
  font-size: 40px;
}
.mintbutton{
  background-color: rgb(25 137 98);
  border-radius: 15px;
  color: BLACK;
  padding: 18px;
  font-size: 35px;
  font-weight: bold;
  align-self: center;
}
.buytiger{
  display: flex;
  padding: 40px;
  justify-content: space-between;
  align-items: center;
}
.textbuy{
  align-self: center;
  font-size: 50px;
}
#buynft hr{
  height: 1px;
  background-color: white;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
#stylespecs{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.stylespecs{
  display: flex;
}
#stylespecs hr{
  height: 1px;
  background-color: white;
 
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
#roadmap{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.roadmap-part{
  display: flex;
}
.roadmap-part .rightpart img{
  border-radius: 30px;
  border: 1px solid white;
  height: 70%;
}
#roadmap hr, #gallery hr{
  height: 1px;
  background-color: white;
 
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.charity{
  display: flex;
}
#charity{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
#charity hr{
  height: 1px;
  background-color: white;
 
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.about{
  display: flex;
}
#about{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.greentext{
  color:#178964;
  font-weight: 800;
}
.about .textleft{
  max-width: 70%;
}
.about .textleft:last-of-type p{
  width: 100%;
}
.club-text{
  margin-top: -30px;
}
.club-text p{
  font-size: 20px;
  line-height: 28px;
}
.percentage{
  margin-right: 50px;
}
.road-item:last-of-type .percentage{
  margin-right: 40px;
}
.road-item:first-of-type .percentage{
  margin-right: 55px;
}
.road-item{
  display: flex;
}
.__2 img{
  position: relative;
}
.overlay img{
  position: relative;
  top: 720px;
  left: -560px;
  width: 250px;
}
.overlay2 img{
  position: relative;
  top: 200px;
  left: -580px;
  width: 250px;
}
.overlay3 img{
  position: relative;

  width: 400px;
}
.overlay4 img{
  position: relative;


  width: 300px;
}
.overlay5 img{
  position: relative;
  top: -350px;
  right: -520px;
  width: 300px;
}
.overlay6 img{
  position: relative;
top:70px;
right: -390px;
  width: 300px;
}
.parentimg{
  background: url(/static/media/cave.ab36fd84.jpg);
  background-size: cover;
  height: 1000px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

@media (max-width: 1600px){
  .overlay img,  .overlay2 img,  .overlay3 img,  .overlay4 img,  .overlay5 img,  .overlay6 img{
    width: 200px;
  } 
  .overlay img{
    left: -450px;
  }
  .overlay2 img{
    left: -450px;
  }
  .overlay3 img{
    left: 100px;
    top: 30px;
    width: 250px;
  }
  .overlay4 img{
    left: 110px;
    top: 120px;
  }
  .overlay5 img{
    top: -200px;
  right: -560px;
  }
  .overlay6 img{
    top:280px;
    right: -490px;
  }
  
}
@media(min-width: 1300px) and (max-width: 1600px){
  .parentimg{
    height: 800px;
  }
  .overlay img{
    position: relative;
    top: 580px;
    left: -424px;
    width: 200px;
  }
  .overlay2 img{
    position: relative;
    top: 120px;
    left: -414px;
    width: 200px;
  }
  .overlay3 img{
    position: relative;
  left: 27px;
  top: -60px;
    width: 300px;
  }
  .overlay4 img{
    position: relative;
  left: 27px;
  top: 0px;
    width: 200px;
  }
  .overlay5 img{
    position: relative;
    top: -300px;
    right: -450px;
    width: 200px;
  }
  .overlay6 img{
    position: relative;
  top:100px;
  right: -310px;
    width: 200px;
  }

}
.hide-800 a, .hide800 a{
  text-decoration: none;
  color: white;
}
@media (max-width: 600px)
{
  .club-text h2{
    margin-top: 0px!important;
  }
  #welcome{
    width: 90%;
  }
  #stylespecs{
    width: 90%;
  }
  #charity{
    width: 90%;
  }
  #about{
    width: 90%;
  }
  #roadmap{
    width: 90%;
  }
  .textleft p{
    font-size: 17px;
  }
  .club-text h2{
    font-size: 22px;
  }
  .club-text p{
    font-size: 19px;
  }
  .percentage{
    font-size: 17px;
  }
  .textroad{
    font-size: 17px;
  }
  .about .textleft{
    max-width: 100%!important;
  }
  .about .textleft:last-of-type{
    max-width: 90%!important;
  }
  .rightpart img{
    width: 340px!important;
  }
  .hideplus600{
    margin-left: auto;
    margin-right: auto;
  }
  .parentimg{
    height: 300px!important;
    width: 100%!important;
  }
  .overlay img{
    position: relative;
    top: 216px;
    left: -152px;
    width: 80px;
  }
  .overlay2 img{
    position: relative;
    top: 43px;
    left: -146px;
    width: 80px;
  }
  .overlay3 img{
    position: relative;
  left: 17px;
  top: -51px;
    width: 100px;
  }
  .overlay4 img{
    position: relative;
  left: 17px;
  top: -19px;
    width: 100px;
  }
  .overlay5 img{
    position: relative;
    top: -127px;
    right: -175px;
    width: 80px;
  }
  .overlay6 img{
    position: relative;
  top:6px;
  right: -128px;
    width: 81px;
  }
  .hide600{
    display: none!important;
  }
  .hideplus600{
    display: block!important;
    width: 300px!important;
    margin-top: 20px;
  }

  .hidephone{
    display: none;
  }
 .showphone{
   display: block!important;
  
 }
 .showphone img{
  width: 410px!important;
 }
}
.hideplus600{
  display: none;
}
.resizeimg img{
  margin-bottom: 40px;
}
.showphone{
  display: none;
}
#charity{
  scroll-margin-top: 100px;
}
#welcome{
  scroll-margin-top: 100px;
}
#roadmap{
  scroll-margin-top: 100px;
}
#buynft{
  scroll-margin-top: 100px;
}
#stylespecs{
  scroll-margin-top: 100px;
}
footer{
  margin-bottom: 30px;
}


.club-text h2{
  margin-top: 20px;
}
#charity a{
  text-decoration: none;
  color: white;
}
.about .textleft:last-of-type{
  margin-bottom: 20px;
}
footer a{
  text-decoration: none;
  color: white;
}
.grid{
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;

}
.row{
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px;
}
.row img{
  width: 200px;
  border-radius: 10px;
}
@media(max-width: 800px){
  .row img{
    width: 140px;
  }
}
#charity .stylespecs .textleft p span{
  color: rgb(25 137 98);
  font-weight: 600;
}
.charities img{
  width: 200px;
  border-radius: 0px;
}
.charities{
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
  gap: 50px;
}
.stylespecs .textleft{
  width: 80%;
}
.stylespecs .textleft p{
  width: 80%;
}
.about{
  padding-bottom: 30px;
}
.mintfoot{
  padding-top: 100px;
}
#gallery{
  width: 80%;
  margin: auto;
}
.gallerygrid{
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-row-gap: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 80vw;
}
.gallerygrid img{
  width: 230px;
  border-radius: 10px;
  margin: auto;
}
@media(max-width: 1600px){
  .gallerygrid img{
    width: 200px;
  }
}
@media(max-width: 1400px){
  .gallerygrid img{
    width: 190px;
  }
}
@media(max-width: 800px)
{
  .gallerygrid{
    grid-template-columns: repeat(2, auto);
  }
  .gallerygrid img{
    width: 140px;
  }
  .charities img{
    width: 250px!important;
  }
  .stylespecs .textleft{
    width: 100%;
  }
  .charities{
    margin-top: 60px;
  }
}
.textleft a{
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  color: white;
}
.mintingpart{
  display: none;
}
.buttonmint{
  background:#178964;
  padding: 19px 20px;
  color: black;
  border-radius: 20px;
}
.MuiButton-label{
  font-weight: 800!important;
}
.MuiContainer-root{
 margin-top: 0px!important;
}
.MuiPaper-root{
  background: transparent!important;
  box-shadow: none!important;
}
.guide{
  width: 170px;
}
.newtobuy{
  text-align: left;
  width: 33%;
  font-size: 30px;
}
.newtobuy a{
  color: white;
}
@media(max-width: 800px){
  .newtobuy{
    width: 100%;
    text-align: center;
  }
  .MuiPaper-root{
    padding: 0px!important;
    padding-top: 20px!important;
  }
  .MuiPaper-elevation0{
    padding-top: 0px!important;
  }
  .guide{
    padding-top: 30px;
  }
}
.chainpr{
  font-family: Chainprinter;
  line-height: 45px;
  margin-top: 20px;
  font-size: 24px;
}
.lineheight{
  line-height: 45px;
  font-size: 24px;
}
.MuiContainer-root{
  padding: 0px!important;
}

:root {
  
  /* Main Settings  */
  --main-background-color: #000000;

  --text-primary-color: rgb(0, 0, 0);
  --text-secondary-color: rgb(68, 68, 68);

  /* Nav Settings  */
  --nav-link-color: rgb(0, 0, 0);
  --nav-link-hover-color: rgb(87, 87, 87);
  --nav-link-hover-background-color: none;
  --nav-background-color: none;
  --nav-height: 150px;
  --nav-logo-width: 90px;
  --nav-logo-font-size: 16px;

  /* Mobile Nav Settings  */

  --mobile-nav-background-color: rgb(0, 0, 0);
  --mobile-nav-logo-height:100px;
  --mobile-nav-link-color: rgb(255, 255, 255);
  --mobile-nav-link-hover-color: rgb(29, 29, 29);
  --mobile-nav-link-size: 20px;

  /* Card Settings  */
  --card-background-color: rgb(216, 216, 216);
  --card-border-radius: 10px;
  --card-shadow: 10px 10px 20px -13px rgb(121, 121, 121);

  /* Minting Area */
  --minting-box-color: #212029;
  --minting-box-heading-color: white;
  --minting-box-info-box-color: rgb(65, 65, 65);
  --minting-box-text-color: white;




}







